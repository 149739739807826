import React from 'react';

const HomeIntro = props => {
  return (
    <section class="intro">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-lg-offset-2">
            <span class="glyphicon glyphicon-apple" style={{fontSize: 60}}></span>
            <h2 class="section-heading">Completely synergize resource taxing relationships</h2>
            <p class="text-light">Professionally cultivate one-to-one customer service with robust ideas. Dynamically innovate resource-leveling customer service for state of the art customer service.</p>
          </div>
        </div>
      </div>
    </section>
  )
};

export default HomeIntro;