import React from 'react';

const HomeProducts = props => {
  return (
    <div class="container-fluid">
      <div class="row promo">
        <a href="/">
          <div class="col-md-4 promo-item item-1">
            <h3>
              Unleash
            </h3>
          </div>
        </a>
        <a href="/">
          <div class="col-md-4 promo-item item-2">
            <h3>
              Synergize
            </h3>
          </div>
        </a>

        <a href="/">
          <div class="col-md-4 promo-item item-3">
            <h3>
              Procrastinate
            </h3>
          </div>
        </a>
      </div>
    </div>
  )
};

export default HomeProducts;