import React from 'react';
import ContactUs from './ContactUs';

const Footer = props => {
  return (
    <footer class="page-footer">
      <ContactUs />

      <div class="small-print">
        <div class="container">
          <p>Copyright &copy; Example.com 2015</p>
        </div>
      </div>

    </footer>
  )
};

export default Footer;