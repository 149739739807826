import React from 'react';

const HomeIntro2 = props => {
  return (
    <section class="content">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <img class="img-responsive img-circle center-block" src="images/microphone.jpg" alt="" />
          </div>
          <div class="col-sm-6">
            <h2 class="section-header">Best in Class</h2>
            <p class="lead text-muted">Holisticly predominate extensible testing procedures for reliable supply chains. Dynamically innovate resource-leveling customer service for state of the art customer service.</p>
            <a href="/" class="btn btn-primary btn-lg">Classify It</a>
          </div>

        </div>
      </div>
    </section>
  )
};

export default HomeIntro2;