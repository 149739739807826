import React from 'react';

const ContactUs = props => {
  return (
    <div class="contact">
      <div class="container">
        <h2 class="section-heading">Contact Us</h2>
        <p><span class="glyphicon glyphicon-earphone"></span><br />+1 (650) 393-3888</p>
        <p><span class="glyphicon glyphicon-envelope"></span><br />excelone.david@gmail.com</p>
      </div>
    </div>
  )
};

export default ContactUs;

// <a href="tel:16503933888">
//   <a style={{textDecoration: 'none'}} href="mailto:excelone.david@gmail.com">