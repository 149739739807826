import React from 'react';

const Nav = props => {
  return (
    <nav id="siteNav" class="navbar navbar-default navbar-fixed-top" role="navigation" dataSpy="affix" dataOffsetTop="100" style={{backgroundColor: 'gray'}}>
      <div class="container">
        <div class="navbar-header">
          <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="/">
            <span class="glyphicon glyphicon-fire"></span>
            LOGO
          </a>
        </div>

        <div class="collapse navbar-collapse" id="navbar">
          <ul class="nav navbar-nav navbar-right">
            <li class="active">
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/">Products</a>
            </li>
            <li class="dropdown">
              <a href="/" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Services <span class="caret"></span></a>
              <ul class="dropdown-menu" aria-labelledby="about-us">
                <li><a href="/">Engage</a></li>
                <li><a href="/">Pontificate</a></li>
                <li><a href="/">Synergize</a></li>
              </ul>
            </li>
            <li>
              <a href="/">Contact</a>
            </li>
          </ul>

        </div>
      </div>
    </nav>
  )
};

export default Nav;