import React from 'react';

const HomeIntro3 = props => {
  return (
    <section class="content content-2">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <h2 class="section-header">Superior Quality</h2>
            <p class="lead text-light">Holisticly predominate extensible testing procedures for reliable supply chains. Dynamically innovate resource-leveling customer service for state of the art customer service.</p>
            <a href="/" class="btn btn-default btn-lg">Test It</a>
          </div>
          <div class="col-sm-6">
            <img class="img-responsive img-circle center-block" src="images/iphone.jpg" alt="" />
          </div>

        </div>
      </div>
    </section>
  )
};

export default HomeIntro3;