import React from 'react';

const HomeMain = props => {
  return (
    <header>
      <div class="header-content">
        <div class="header-content-inner">
          <h1>Excel One Construction</h1>
          <p>Objectively innovate empowered manufactured products whereas parallel platforms.</p>
          <a href="/" class="btn btn-primary btn-lg">Engage Now</a>
        </div>
      </div>
    </header>
  )
};

export default HomeMain;