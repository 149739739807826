import './App.css';
import Nav from './sections/Nav';
import HomeMain from './sections/HomeMain';
import HomeIntro from './sections/HomeIntro';
import HomeIntro2 from './sections/HomeIntro2';
import HomeIntro3 from './sections/HomeIntro3';
import HomeProducts from './sections/HomeProducts';
import HomeIntro4 from './sections/HomeIntro4';
import Footer from './sections/Footer';

function App() {
  return (
    <div className="App">

      <Nav />

      <HomeMain />

      <HomeIntro />

      <HomeIntro2 />

      <HomeIntro3 />

      <HomeProducts />

      <HomeIntro4 />

      <Footer />
      
    </div>
);
}


export default App;
