import React from 'react';

const HomeIntro4 = props => {
  return (
    <section class="content content-3">
      <div class="container">
        <h2 class="section-header">
          <span class="glyphicon glyphicon-pushpin text-primary"></span><br /> Sanity Check
        </h2>
        <p class="lead text-muted">Holisticly predominate extensible testing procedures for reliable supply chains. Dynamically innovate resource-leveling customer service for state of the art customer service.</p>
        <a href="/" class="btn btn-primary btn-lg">Check Now</a>
      </div>
    </section>
  )
};

export default HomeIntro4;